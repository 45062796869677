import { persisted } from 'svelte-persisted-store';
import { derived } from 'svelte/store';
import { type Permissions } from './permissions';

export interface User {
	id: number;
	username: string;
	avatar: string;
}

export interface UserInfo {
	active: boolean;
	verified: boolean;
	renewed: boolean;
	incomplete_account: boolean;
	permissions: Permissions;
	user_data: User;
	email: string;
}

export const user = persisted('user', null as User | null);

export const user_info = persisted('userinfo', null as UserInfo | null);

export const authenticated = derived(
	user,
	($user) => $user?.username !== null && $user?.username !== undefined && $user?.username.length > 0
);
