import { derived } from 'svelte/store';
import { persisted } from 'svelte-persisted-store';

export interface Permissions {
	chat_submit: boolean;
	chat_edit_own: boolean;
	chat_edit_own_time: number;
	chat_edit_all: boolean;
	chat_hide_own: boolean;
	chat_hide_own_time: number;
	chat_hide_all: boolean;
	chat_view_hidden: boolean;
	chat_ban: boolean;
	topic_new: boolean;
	topic_edit_own: boolean;
	topic_edit_own_time: number;
	topic_sticky: boolean;
	topic_edit_all: boolean;
	topic_lock: boolean;
	topic_move: boolean;
	topic_hide_own: boolean;
	topic_hide_own_time: number;
	topic_hide_all: boolean;
	post_new: boolean;
	post_edit_own: boolean;
	post_edit_own_time: number;
	post_edit_all: boolean;
	post_hide_all: boolean;
	post_hide_own: boolean;
	post_hide_own_time: number;
	board_lock: boolean;
	reactions_like: boolean;
	reactions_agree: boolean;
	reactions_insightful: boolean;
	reactions_promote: boolean;
	forum_hidden_view: boolean;
	server_add_ban: boolean;
	server_edit_ads: boolean;
	member_edit: boolean;
	member_list_view: boolean;
	tournament_manage: boolean;
	admin_menu: boolean;
	fivem_citizen_info: boolean;
}

export const permissions = persisted('permissions', null as Permissions | null);

export const is_admin = derived(
	permissions,
	($permissions) => $permissions !== null && $permissions?.admin_menu
);
